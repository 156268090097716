
/* Add a transition to the hr tag */
.underline {
  opacity: 1;
  height: 2px; 
}

/* h1 tag styles */
.header h1.hide {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

/* hide the HR element */
.hidden-hr {
  opacity: 0;
  height: 0;
  overflow: hidden;
}



.delete-button {
  color: red;
  margin-left: auto; 
}

/* Font style for the selected item */
.selected-text {
  font-size: 20px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 10px;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.header-right h1{
  font-size: 31px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.selected-items {
  display: flex; 
  align-items: center;
  height: 40px;
}

.selected-text {
  margin-right: 10px;
}

.delete-button {
  border: none;
  background-color: #fff;
}

.header h1, .selected-items {
  transition: none;
  opacity: 1;
}
