.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.medium-image {
  grid-column: 1 / 1.2;
  grid-row: 1 / 3;
  grid-column-start: 1;
  position: relative;
}

/* Style the medium image */
.medium-image img {
  max-width: 100%;
  height: auto;
  border: 1px solid #d3d4d7;
  border-radius: 10px;
  transition: opacity 0.3s;
  position: relative;
  transform: scale(1);
  transition: transform 0.3s;
}

/* Style the checkbox for the medium image */
.medium-image .image-checkbox {
  display: block;
  position: absolute;
  top: 10%;
  left: 5%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* Add a hover effect */
.medium-image:hover img {
  opacity: 0.4;
}

/* Apply a grow effect to the image when dropped */
.bottom-row img.dropping.grow {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.right-images {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
  position: relative;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.row img {
  max-width: 100%;
  height: auto;
  border: 1px solid #d3d4d7;
  border-radius: 10px;
}

.row1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.row1 img {
  max-width: 100%;
  height: auto;
  border: 1px solid #d3d4d7;
  border-radius: 10px;
}

/* Add a hover effect */
.row img:hover {
  opacity: 0.3;
}

.bottom-row {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  position: relative;
}

.bottom-row img {
  max-width: 100%;
  height: auto;
  border: 1px solid #d3d4d7;
  border-radius: 10px;
  transition: opacity 0.3s;
}

/* Add a hover effect */
.bottom-row img:hover {
  opacity: 0.3;
}

.right-side img,
.medium-image img {
  max-width: 65%;
  max-height: 100%;
}

.image-checkbox {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 12px;
  left: 15px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the checkbox when the parent container is hovered */
.image-container:hover .image-checkbox {
  opacity: 1;
}

/* Keep the checkbox visible when it's selected */
.image-checkbox.selected {
  opacity: 1;
}

/* Image container styles */
.image-container {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border: 2px solid transparent;
  width: 100%;
}



.image-container.selected {
  border: 2px solid #fff;
}

.image-container:hover .image-checkbox {
  display: block;
}


/* Add a hover effect */
.image-container:hover img {
  opacity: 0.7;
}

/* Style the checkbox label */
.image-container input.image-checkbox + label {
  display: block;
  position: absolute;
  top: -60px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

/* Add a transition to smoothly change the image size */
.image-container img {
  transition: transform 0.3s ease-in-out;
}


/* Add a hover effect for image containers */
.image-container:hover img {
  opacity: 0.7;
}

/* Styles for selected images */
.image-container.selected img {
  border: 2px solid #fff;
}

/* Show the checkbox when the parent container is hovered */
.image-container:hover .image-checkbox {
  opacity: 1;
}

/* Keep the checkbox visible when it's selected */
.image-checkbox.selected {
  opacity: 1;
}

.selected-image-checkbox {
  display: none;
}

[data-rbd-drag-handle-context-id="0"] {
  cursor: pointer;
}

/* Apply a grow effect to the image when dropped */
.medium-image img.dropping {
  transform: scale(1.3);
  transition: transform 0.3s;
}

/* Styles for medium-sized screens (tablets) */
@media (max-width: 768px) {
  .medium-image {
    grid-column: 1 / 3;
    grid-row: auto;
  }

  .medium-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s;
  }

  .medium-image .image-checkbox {
    display: none;
  }

  .medium-image:hover {
    opacity: 1;
  }

  .right-images {
    grid-column: 1 / 3;
    grid-row: auto;
  }
}

/* Styles for small screens (mobile) */
@media (max-width: 480px) {
  .container {
    grid-template-columns: 1fr;
  }

  .medium-image,
  .right-images,
  .bottom-row {
    grid-column: auto;
    grid-row: auto;
  }

  .image-container {
    margin: 10px;
    width: 100%;
  }

  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s;
  }

  .image-container .image-checkbox {
    display: none;
  }

  .image-container:hover .image-checkbox {
    display: block;
  }

  .image-container .image-checkbox.selected {
    display: block;
  }

  .image-container:hover img {
    opacity: 0.7;
  }

  .image-container.selected {
    border: 2px solid #fff;
  }

  .image-container:hover {
    opacity: 1;
  }
}
